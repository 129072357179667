import { useModals } from '@/compositions/modals'

type Numbers = () => {
  openAddNumbersLabelModal: () => void
  openEditNumbersLabelModal: () => void
}

const useNumbersOpenModal: Numbers = () => {
  const { openModal } = useModals()

  const openAddNumbersLabelModal = () => {
    openModal('servicesNumbersLabel', {
      modalTitle: 'Add number label',
    })
  }

  const openEditNumbersLabelModal = () => {
    openModal('servicesNumbersLabel', {
      modalTitle: 'Edit number label',
      label: 'Marketing USA',
      isEdit: true,
    })
  }

  return {
    openAddNumbersLabelModal,
    openEditNumbersLabelModal,
  }
}

export default useNumbersOpenModal
