
import {
  defineComponent, ref, computed
} from 'vue'
import ServicesNumbersTable from '@/components/pages/services/numbers/ServicesNumbersTable.vue'
import ServicesNumbersFilter from '@/components/pages/services/numbers/ServicesNumbersFilter.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ServicesNumber } from '@/definitions/services/numbers/types'
import { formatDate } from '@/services/dateTimeService'
import { getTableData } from '@/services/tableService'

export default defineComponent({
  components: {
    ServicesNumbersTable,
    ServicesNumbersFilter,
  },
  setup() {
    const search = ref('')
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Number', value: 'number-slot' },
      { text: 'Label', value: 'label-slot' },
      { text: 'Account', value: 'account-slot' },
      { text: 'Price / month', value: 'price' },
      {
        text: 'Next renewal',
        value: 'nextRenewal',
        format: (val: string) => formatDate(val, 'D MMM'),
      },
      { text: 'Forward calls to', value: 'forward-calls-to-slot' },
    ])

    const tableItems = getTableData('servicesNumbers') as ServicesNumber[]

    const filteredTableItems = computed(() => tableItems.filter((el) => (el.number.value.includes(search.value))))

    return {
      search,
      tableHeaders,
      filteredTableItems,
    }
  },
})
