import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_services_numbers_filter = _resolveComponent("services-numbers-filter")!
  const _component_services_numbers_table = _resolveComponent("services-numbers-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_services_numbers_filter, {
      search: _ctx.search,
      "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
    }, null, 8, ["search"]),
    _createVNode(_component_services_numbers_table, {
      headers: _ctx.tableHeaders,
      items: _ctx.filteredTableItems
    }, null, 8, ["headers", "items"])
  ]))
}