
import type { PropType } from 'vue'
import { defineComponent, toRefs, computed } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import type { ServicesNumber } from '@/definitions/services/numbers/types'
import useNumbersOpenModal from '@/compositions/services/useNumbersOpenModal'

export default defineComponent({
  components: {
    TmDropdown,
    TmTableActionButton,
    TmDropdownItem,
  },
  props: {
    number: {
      type: Object as PropType<ServicesNumber>,
      required: true,
    },
  },
  setup(props) {
    const { number } = toRefs(props)
    const { openEditNumbersLabelModal } = useNumbersOpenModal()

    const showSetting = computed(() => !(typeof number.value.forwardCallsTo === 'boolean'))

    const showRegistration = computed(() => number.value.forwardCallsTo === null)

    const showDeactivate = computed(() => (
      (number.value.forwardCallsTo && number.value.forwardCallsTo.name === 'SMS chat') ||
      showRegistration.value
    ))

    return {
      openEditNumbersLabelModal,
      showSetting,
      showRegistration,
      showDeactivate,
    }
  },
})
