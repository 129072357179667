import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_table_action_button = _resolveComponent("tm-table-action-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, null, {
    menu: _withCtx(() => [
      (_ctx.showSetting)
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 0,
            to: { name: 'base.services.numbers.edit.step2' },
            icon: "settings",
            label: "Forwarding settings"
          }, null, 8, ["to"]))
        : _createCommentVNode("", true),
      (_ctx.showDeactivate)
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 1,
            icon: "arrow_downward",
            label: "Deactivate call forwarding"
          }))
        : _createCommentVNode("", true),
      (_ctx.showRegistration)
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 2,
            icon: "assignment",
            label: "Campaign registration"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_dropdown_item, {
        icon: "edit",
        label: "Edit label",
        onClick: _ctx.openEditNumbersLabelModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_tm_dropdown_item, {
        icon: "tmi-clear",
        label: "Cancel"
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_table_action_button)
    ]),
    _: 1
  }))
}