
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import ServicesNumbersDropdown from './ServicesNumbersDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ServicesNumber } from '@/definitions/services/numbers/types'
import { formatDate } from '@/services/dateTimeService'
import TmPerson from '@/components/shared/TmPerson.vue'
import useNumbersOpenModal from '@/compositions/services/useNumbersOpenModal'

export default defineComponent({
  components: {
    TmPerson,
    TmCountry,
    TmTable,
    TmBadge,
    TmButton,
    TmStatus,
    ServicesNumbersDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<ServicesNumber[]>,
      required: true,
    },
  },
  setup() {
    const { openAddNumbersLabelModal } = useNumbersOpenModal()

    return {
      formatDate,
      openAddNumbersLabelModal,
    }
  },
})
